import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Colors from "../constants/Colors"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Siden findes ikke" />
        <div style={card}>
          <h1 style={headline}>Vi kan ikke finde siden</h1>
          <p>
            Se alle <a href="https://blog.firma-navn.dk/">artikler her</a>.
          </p>
          <p>
            Gå til <a href="https://www.firma-navn.dk/">firma-navn.dk her</a>.
          </p>
        </div>
        <br></br>
      </Layout>
    )
  }
}

export default NotFoundPage

const card = {
  backgroundColor: Colors.white,
  padding: 30,
  borderRadius: 15
};

const headline = {
  color: Colors.almostBlack
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
